<script setup>
    const { t: $t } = useI18n();
    const localePath = useLocalePath();

    const menu = [
        {
            label: $t('Imports in progress'),
            icon: 'mdi:progress-helper',
            to: localePath('products-import')
        }, {
            label: $t('Import an Excel file'),
            icon: 'mdi:microsoft-excel',
            to: localePath('products-import-excel')
        }, {
            label: $t('Import a truck'),
            icon: 'mdi:truck-cargo-container',
            to: localePath('products-import-truck')
        }, {
            label: $t('Create a product'),
            icon: 'mdi:layers-plus',
            to: localePath('products-create')
        }
    ];
</script>

<template>
    <PrimevueTabMenu :model="menu" />
</template>
