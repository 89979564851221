<template>
    <NuxtLayout name="backend" :pageUrl="localePath('products-import')">
        <div class="flex justify-content-between align-items-center mb-4">
            <h2>{{ $t('Products imports') }}</h2>

            <NuxtLink :to="localePath('offers-composition')">
                <PrimevueButton
                    :label="$t('Create an offer')"
                    severity="secondary"
                />
            </NuxtLink>
        </div>

        <Card :pt="{body: {class: 'border-none p-5'}, header: {class: 'p-0'}}">
            <template #header>
                <ProductImportMenu />
            </template>

            <slot />
        </Card>
    </NuxtLayout>
</template>
